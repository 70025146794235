import React , { Component } from 'react';
import './places.css'

class HomePlaces extends Component {
    constructor(props){
        super(props);
    }
    render () {
        console.log("this.props", this.props);
        return (
            <div>
                {this.props.result.roomsDetails.map((place) => {
                    return(
                            <div className="listItemBox">
                                {
                                    place.items[0].must.map((mustItem) => {
                                    return (
                                        <div className="mustHaveItemBox">
                                            {mustItem.name}
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    )
                }
            </div>
        )
    }
}

export default HomePlaces;
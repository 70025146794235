import React, { Component } from 'react';
import fireBase from '../../config/fireBase';

class Login extends Component {
    constructor(props){
        super(props);
        this.login = this.login.bind(this);
        this.signup = this.signup.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            email: '',
            password: ''
        }
    }

    login(e){
        e.preventDefault();
        fireBase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then((u) => {}).catch((error) => {
            console.log(error);
        })
    }

    signup(e){
        e.preventDefault();
        fireBase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password).then((u)=>{
            console.log(u);
        }).catch((error)=>{
            console.log(error);
        })
    }
    
    handleChange(e){
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {
        return (
            <div>
                <form>
                    <div>
                        <label for="inputEmail">Email Address</label>
                        <input value={this.state.email} onChange={this.handleChange}
                        type="email" name="email" id="inputEmail"
                        placeholder="Enter Mail" />
                    </div>
                    <div>
                        <label for="inputPassword">Password</label>
                        <input value={this.state.password} onChange={this.handleChange}
                        type="password" name="password" id="inputPassword" 
                        placeholder="Password" />
                    </div>
                    <button type="submit" onClick={this.login}>Login</button>
                    <button onClick={this.signup}>Signup</button>
                </form>
            </div>
        )
    }
} 

export default Login;
import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyDARlh--FdH8tB_KzaBwIWPr2739PenkAI",
    authDomain: "expert-planner.firebaseapp.com",
    databaseURL: "https://expert-planner.firebaseio.com",
    projectId: "expert-planner",
    storageBucket: "expert-planner.appspot.com",
    messagingSenderId: "396376412055",
    appId: "1:396376412055:web:738ca89c7a643fdc347ed2",
    measurementId: "G-CE5YX699KQ"
  };

  const fireBase = firebase.initializeApp(firebaseConfig);
  export default fireBase;
import React, { Component } from 'react';
import fireBase from '../../config/fireBase';
import HomePlaces from '../places';


class Home extends Component {
    constructor (props){
        super(props);
        this.logout = this.logout.bind(this);
        this.state = {
            itemClicked : false,
            result : []
        }
    }
    onCLickGetDetails = () => {
        fetch("http://www.json-generator.com/api/json/get/cedrhserUy?indent=2")
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    itemClicked: true,
                    result: result[0]
                });
                console.log("result", result);
            },
            (error) => {
                console.log("error", error);
            }
        )
    }

    logout(){
        fireBase.auth().signOut();
    }

    render () {
        return (
            <div>
                {
                    this.state.itemClicked 
                    ? (<div>
                        <HomePlaces
                            result = {this.state.result}
                        />   
                    </div>)
                    : (
                    <div onClick={this.onCLickGetDetails}>
                        Home
                        <button onClick={this.logout}Logout></button>
                    </div>)
                }
            </div>
        )
    }
}


export default Home;
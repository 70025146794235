import React, { Component } from 'react';
import './App.css';
import fireBase from '../config/fireBase';
import Home from './home';
import Login from './login';

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      user : {}
    }
  }

  componentDidMount() {
    this.authListener();
  }

  authListener() {
    fireBase.auth().onAuthStateChanged((user) => {
      if(user){
        this.setState({user});
      }else {
        this.setState({user: null});
      }
    });
  }
  render() {
    return (
      <div className="App">
        <header className="App-header">
          {this.state.user ? <Home /> : <Login/>}
        </header>
      </div>
    );
  }
}

export default App;
